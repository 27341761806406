/*
  Configuration needed to simulate an API in a development environment

  import ApiServiceFake from '@/common/bridges/fake'
  import { ENV } from '@/common/config'
  const service = ENV === 'development' ? ApiServiceFake : ApiServiceReal
*/
import {
  API_REFACIL_URL,
  API_APAGAR_URL,
  API_REFACIL_OLD_URL,
  API_REFACIL_SELL_URL,
  API_REFACIL_PAY_URL,
  API_REFACIL_REQUIREMENTS_URL,
  API_REFACIL_REPORTS_URL,
  API_REFACIL_CREDITS_URL,
  API_REFACIL_AUTHORIZER_URL,
  API_DATA_ANALYTICS,
  API_ADMIN,
} from './config';
import ApiServiceReal, { axiosIntances } from './bridges/real';
import axios from 'axios';

const services = {
  ApiService: { baseURL: API_REFACIL_URL },
  ApiServiceRefacilOld: { baseURL: API_REFACIL_OLD_URL },
  ApiServiceSell: { baseURL: API_REFACIL_SELL_URL },
  ApiServicePay: { baseURL: API_REFACIL_PAY_URL },
  ApiApagarService: { baseURL: API_APAGAR_URL },
  ApiServiceRequirement: { baseURL: API_REFACIL_REQUIREMENTS_URL },
  ApiServiceReports: { baseURL: API_REFACIL_REPORTS_URL },
  ApiServiceCredits: { baseURL: API_REFACIL_CREDITS_URL },
  ApiServiceAuthorizer: { baseURL: API_REFACIL_AUTHORIZER_URL },
  ApiServiceDataAnalytics: { baseURL: API_DATA_ANALYTICS },
  ApiServiceAdmin: { baseURL: API_ADMIN },
};

const names = Object.keys(services);
for (const name of names) {
  services[name] = axios.create({
    baseURL: services[name].baseURL,
  });
}

const axiosIntancesKeys = axiosIntances(...Object.values(services));

for (let key in names) {
  services[names[key]] = new ApiServiceReal(axiosIntancesKeys[key]);
}

export default services;
