import AuthService from './auth.service';
import MixinService from './mixin.service';
import BrandsService from './brands.service';
import services from './api.service';
import useApi from './hooks/useApi';
import { specialRoutes, whatsappExclude } from './common/specialRoutes';
import { getHashComputer } from './common/device';
import version from './version';

export {
	AuthService,
	MixinService,
	BrandsService,
	getHashComputer,
	useApi,
	specialRoutes,
	whatsappExclude,
	version
};
export default services;
