import { Enviroment } from '../common';

export const API_REFACIL_URL =
	Enviroment[process.env.NODE_ENV].BASE_URL_REFACIL;
export const API_APAGAR_URL = Enviroment[process.env.NODE_ENV].BASE_URL_APAGAR;

//refacil old
export const API_REFACIL_OLD_URL =
	Enviroment[process.env.NODE_ENV].BASE_URL_REFACIL;

export const API_REFACIL_SELL_URL =
	Enviroment[process.env.NODE_ENV].BASE_URL_REFACIL_SELL;
export const API_REFACIL_PAY_URL =
	Enviroment[process.env.NODE_ENV].API_REFACIL_PAY_URL;

export const API_REFACIL_REQUIREMENTS_URL =
	Enviroment[process.env.NODE_ENV].API_REFACIL_REQUIREMENTS_URL;

export const API_REFACIL_REPORTS_URL =
	Enviroment[process.env.NODE_ENV].API_REFACIL_REPORTS_URL;

export const API_REFACIL_CREDITS_URL =
	Enviroment[process.env.NODE_ENV].API_REFACIL_CREDITS_URL;

export const API_REFACIL_AUTHORIZER_URL =
	Enviroment[process.env.NODE_ENV].API_REFACIL_AUTHORIZER_URL;

export const API_DATA_ANALYTICS =
	Enviroment[process.env.NODE_ENV].API_DATA_ANALYTICS;

export const API_ADMIN =
	Enviroment[process.env.NODE_ENV].API_ADMIN;
