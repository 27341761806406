import Vue from 'vue';
import VueAxios from 'vue-axios';
import { getHashComputer } from '../common/device';

let _Store = null;
let _Vue = null;

const ACTION_UNAUTHORIZED = 'unauthorized';

export const axiosIntances = (...args) => {
	const axiosIntances = {};
	args.forEach((arg, index) => (axiosIntances[`axios${index}`] = arg));
	Vue.use(VueAxios, axiosIntances);
	return Object.keys(axiosIntances);
};

export default class ApiService {
	constructor(axiosIntance) {
		this.axiosIntance = axiosIntance;
	}

	init(Store) {
		_Store = Store;
		this.setHeader();
	}

	setVue(vue) {
		_Vue = vue;
	}

	setHeader() {
		Vue[this.axiosIntance].defaults.headers.common['Content-Language'] = 'es';

		const token = _Store.getters.user ? _Store.getters.user.token : '';
		if (token) {
			Vue[
				this.axiosIntance
				// ].defaults.headers.common.Authorization = `Token MTY1NjQ1NDU0NDA2MSQyYiQxMCRpWUdzZnVKcVF0bVJQQnpKczNRRkV1amhGSTNKVzJrVEkwUHpXR0hFaTFhT08xRzZyRC5pLg==`;
			].defaults.headers.common.Authorization = `Token ${token}`;
			// ].defaults.headers.common.Authorization = `Bearer ${token}`;
		}
	}

	query(resource, params) {
		if (!params) params = '';
		return Vue[this.axiosIntance].get(resource, params).catch(error => {
			throw new Error(`[RWV] ApiService ${error}`);
		});
	}

	get(resource, slug = '') {
		if (!slug) slug = '';
		// console.error(resource, slug);
		return Vue[this.axiosIntance]
			.get(`${resource}/${slug}`)
			.then(result => Promise.resolve(result.data))
			.catch(this._handleError);
	}

	download(resource, slug = '') {
		if (!slug) slug = '';
		return Vue[this.axiosIntance]
			.get(`${resource}/${slug}`, { responseType: 'arraybuffer' })
			.then(result => Promise.resolve(result.data));
	}

	async post(resource, params) {
		if (!params) {
			params = {};
		} else {
			params._channel = params._channel || 'web';
			params._deviceKey = await getHashComputer();
		}

		return Vue[this.axiosIntance]
			.post(`${resource}`, params)
			.then(result => Promise.resolve(result.data))
			.catch(this._handleError);
	}

	async put(resource, params) {
		if (!params) {
			params = {};
		} else {
			params._channel = 'web';
			params._deviceKey = await getHashComputer();
		}

		return Vue[this.axiosIntance]
			.put(`${resource}`, params)
			.then(result => Promise.resolve(result.data))
			.catch(this._handleError);
	}

	async delete(resource, params) {
		if (!params) {
			params = {};
		} else {
			params._channel = 'web';
			params._deviceKey = await getHashComputer();
		}

		return Vue[this.axiosIntance]
			.delete(`${resource}`, params)
			.then(result => Promise.resolve(result.data))
			.catch(this._handleError);
	}

	postMultiPart(resource, params) {
		if (!params) params = {};
		return Vue[this.axiosIntance]
			.post(`${resource}`, params, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(result => Promise.resolve(result.data))
			.catch(this._handleError);
	}

	_handleError(error) {
		if (!error?.response?.data)
			return Promise.reject({
				message: 'Error al conectarse con el servidor.'
			});

		const result = error.response.data;
		if (error.response.status === 401) {
			window.clearInterval(window.threadRealTime);
			_Store.dispatch(ACTION_UNAUTHORIZED, result);
		}

		return Promise.reject(result);
	}
}
