import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

const ID_TOKEN_KEY = 'jHHtoTkUsRfAfu3';
const USER_TOKEN_KEY = 'fTkUsRfAu31';
const BRAND_TOKEN_KEY = 'BrTokLSt3xRB';
const USER_SECRET_KEY = 'Qng8e4MxGmqrc4D';
const BEST_SELLING_BILLS = 'Ked9lLOG2KcmDf6g5er';

export const getToken = () => {
	return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getUser = () => {
	const encryptedUser = window.localStorage.getItem(USER_TOKEN_KEY);
	if (!encryptedUser) return null;
	const bytes = AES.decrypt(encryptedUser, USER_SECRET_KEY);
	const decryptedUser = bytes.toString(Utf8);
	return JSON.parse(decryptedUser);
};

export const getBestSellingBills = () => {
	var invoices = window.localStorage.getItem(BEST_SELLING_BILLS);
	return invoices ? JSON.parse(invoices) : null;
};

export const getBrand = () => {
	var brand = window.localStorage.getItem(BRAND_TOKEN_KEY);
	return brand ? JSON.parse(brand) : null;
};

export const saveToken = token => {
	window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const saveUser = user => {
	const encryptedUser = AES.encrypt(
		JSON.stringify(user),
		USER_SECRET_KEY
	).toString();
	window.localStorage.setItem(USER_TOKEN_KEY, encryptedUser);
};

export const saveBrand = brand => {
	window.localStorage.setItem(BRAND_TOKEN_KEY, JSON.stringify(brand));
};
export const saveBestSellingBills = bills => {
	window.localStorage.setItem(BEST_SELLING_BILLS, JSON.stringify(bills));
};

export const destroyToken = () => {
	window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const destroyBestSellingBills = () => {
	window.localStorage.removeItem(BEST_SELLING_BILLS);
};

export const destroyUser = () => {
	window.localStorage.removeItem(USER_TOKEN_KEY);
};

export default {
	getToken,
	saveToken,
	destroyToken,
	getUser,
	saveUser,
	destroyUser,
	getBrand,
	saveBrand,
	getBestSellingBills,
	saveBestSellingBills,
	destroyBestSellingBills
};
