export const Enviroment = {
  production: {
    BASE_URL_APAGAR: 'https://qa.apagar.co/api/v1/',
    BASE_URL_REFACIL: 'https://core.qa.refacil.co',
    BASE_URL_REFACIL_SELL: 'https://movement.qa.refacil.co',
    API_REFACIL_PAY_URL: 'https://payment.qa.refacil.co',
    API_REFACIL_REQUIREMENTS_URL: 'https://requirement.qa.refacil.co',
    API_REFACIL_REPORTS_URL: 'https://reports.qa.back.refacil.co/',
    // BASE_URL_REFACIL: 'https://testing.refacil.co',
    API_REFACIL_CREDITS_URL: 'https://credit.qa.refacil.co',
    API_REFACIL_AUTHORIZER_URL: 'https://authorizer.qa.refacil.co',
    API_DATA_ANALYTICS: 'https://ms-data-analytics.qa.refacil.co',
	  API_ADMIN: 'https://admin-core.qa.refacil.co'
  },
  development: {
    BASE_URL_APAGAR: 'https://qa.apagar.co/api/v1/',
    BASE_URL_REFACIL: 'https://core.qa.refacil.co',
    BASE_URL_REFACIL_SELL: 'https://movement.qa.refacil.co',
    API_REFACIL_PAY_URL: 'https://payment.qa.refacil.co',
    API_REFACIL_REQUIREMENTS_URL: 'https://requirement.qa.refacil.co',
    API_REFACIL_REPORTS_URL: 'https://reports.qa.back.refacil.co/',
    // BASE_URL_REFACIL: 'https://testing.refacil.co',
    API_REFACIL_CREDITS_URL: 'https://credit.qa.refacil.co',
    API_REFACIL_AUTHORIZER_URL: 'https://authorizer.qa.refacil.co',
    API_DATA_ANALYTICS: 'https://ms-data-analytics.qa.refacil.co',
	  API_ADMIN: 'https://admin-core.qa.refacil.co'
  },
  testing: {
    BASE_URL_APAGAR: 'https://qa.apagar.co/api/v1/',
    BASE_URL_REFACIL: 'https://core.qa.refacil.co',
    BASE_URL_REFACIL_SELL: 'https://movement.qa.refacil.co',
    API_REFACIL_PAY_URL: 'https://payment.qa.refacil.co',
    API_REFACIL_REQUIREMENTS_URL: 'https://requirement.qa.refacil.co',
    API_REFACIL_REPORTS_URL: 'https://reports.qa.back.refacil.co/',
    // BASE_URL_REFACIL: 'https://testing.refacil.co',
    API_REFACIL_CREDITS_URL: 'https://credit.qa.refacil.co',
    API_REFACIL_AUTHORIZER_URL: 'https://authorizer.qa.refacil.co',
    API_DATA_ANALYTICS: 'https://ms-data-analytics.qa.refacil.co',
	  API_ADMIN: 'https://admin-core.qa.refacil.co'
  },
};
