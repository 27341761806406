import services from '../../api.service';

const SERVICES = {
	core: services.ApiService,
	requirement: services.ApiServiceRequirement,
	core: services.ApiService,
	pay: services.ApiServicePay,
	sell: services.ApiServiceSell
};

export default function useApi(api) {
	let response = {};
	let error = {};
	let loading = {};

	const SERVICE = SERVICES[api];

	const request = async (path, data = null) => {
		error.value = null;

		try {
			loading.value = true;
			const result = await SERVICE.post(path, data);
			response.value = result;
		} catch (err) {
			error.value = err;
		} finally {
			loading.value = false;
		}
	};

	const getRequest = async path => {
		error.value = null;

		try {
			loading.value = true;
			const result = await SERVICE.get(path);
			response.value = result;
		} catch (err) {
			error.value = err;
		} finally {
			loading.value = false;
		}
	};

	const requestMultipart = async (path, data = null) => {
		error.value = null;

		try {
			const result = await SERVICE.postMultiPart(path, data); //change for request multipart
			response.value = result;
		} catch (err) {
			error.value = err;
		} finally {
			loading.value = false;
		}
	};

	return { response, error, request, getRequest, requestMultipart, loading };
}
