import Fingerprint2 from 'fingerprintjs2';
export const getHashComputer = () => {
	const PREFIX = 'PlW3B';
	const SUFIX = 'HjlsP1';
	let resolveHash = (_vid, resolve) => resolve(PREFIX + _vid + SUFIX);

	return new Promise(resolve => {
		let _vid = localStorage.getItem('_vid');
		if (_vid) {
			return resolveHash(_vid, resolve);
		}

		Fingerprint2.get(function (components) {
			var values = components.map(component => component.value);
			var murmur = Fingerprint2.x64hash128(values.join(''), 31);
			localStorage.setItem('_vid', murmur);
			return resolveHash(murmur, resolve);
		});
	});
};
