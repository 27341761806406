export default {
	money: (n, decimals) => {
		var c = decimals | 0,
			d = ',',
			t = '.',
			s = n < 0 ? '-' : '',
			i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
			j = (j = i.length) > 3 ? j % 3 : 0;
		return (
			s +
			(j ? i.substr(0, j) + t : '') +
			i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
			(c
				? d +
				  Math.abs(n - parseInt(i))
						.toFixed(c)
						.slice(2)
				: '')
		);
	},
	safariDetect: () => {
		let rexDetect = /constructor/i;
		return (
			rexDetect.test(window.HTMLElement) ||
			(function (p) {
				return p.toString() === '[object SafariRemoteNotification]';
			})(
				!window['safari'] ||
					(typeof safari !== 'undefined' && window['safari'].pushNotification)
			)
		);
	},
	os: agent => {
		var OSName = 'android';
		if (agent.indexOf('Windows') != -1) OSName = 'windows';
		else if (agent.indexOf('Android') != -1) OSName = 'android';
		else if (agent.indexOf('Dataphone') != -1) OSName = 'dataphone';
		else if (agent.indexOf('Mac') != -1) OSName = 'apple';
		else if (agent.indexOf('X11') != -1) OSName = 'linux';
		else if (agent.indexOf('Linux') != -1) OSName = 'linux';
		else if (agent.indexOf('CFNetwork') != -1) OSName = 'apple';

		return OSName;
	},
	count: (arr, key, condition) => {
		var count = 0;
		for (var i = 0; i < arr.length; i++) {
			if (arr[i][key] == condition) {
				count += 1;
			}
		}
		return count;
	},
	overflowText: (text, char_counter = 50) => {
		if (text.length > char_counter) {
			return text.substr(0, 5) + '...' + text.substr(-5);
		}

		return text;
	},
	formatDate: date => {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		return [year, month, day].join('-');
	},
	paginate: (page, paginate) => {
		if (!paginate.to) {
			return [];
		}
		var offset = 5;
		var from = paginate.current_page - offset;
		if (from < 1) {
			from = 1;
		}

		var to = from + offset * 2;
		if (to >= paginate.last_page) {
			to = paginate.last_page;
		}

		var pagesArray = [];
		while (from <= to) {
			pagesArray.push(from);
			from++;
		}
		return pagesArray;
	},
	round: function (number, key, fn = 'floor') {
		return Math[fn](number * 10 ** key) / 10 ** key;
	},
	netTip: function (tip, inverse = false) {
		if (!tip) return 0;
		let t = tip / 100;
		if (inverse) {
			t = t / (1 + t);
		} else {
			t = t / (1 - t);
		}
		return t * 100;
	},
	printHtml(html, width = '5cm', height = '9cm', font = '11px') {
		if (window.sendMessageToElectron) {
			return window.sendMessageToElectron(html);
		}

		html = `<html><head><title>${document.title}</title>
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" media='screen,print' onload="printBill()">

    <style media='screen,print'>
    body {
      font-size: ${font};
      max-width: ${width}!important;
      min-width: ${width}!important;
      width: ${width}!important;
    }
    h3 {
      font-size: 11px; 
      font-weight: bold; 
    }
     @page {
      size: ${width} ${height};
      margin: 0cm;
     }
    </style>
    <script>
     function printBill() {
      window.print();
      document.close();
      window.close();
     }
    </script>
    </head><body>${html}</body></html>`;
		const mywindow = window.open('', 'PRINT', 'height=400,width=600');

		mywindow.document.write(html);

		// mywindow.document.close(); // necessary for IE >= 10
		mywindow.focus();
		// mywindow.close();
	},
	overdraft(arrayProducts) {
		// eslint-disable-next-line no-undef
		let products = Store.state.products.products;
		for (let i = 0; i < products.length; i++) {
			const product = products[i];
			let pos = arrayProducts.indexOf(product.id);

			if (pos === -1) {
				continue;
			}
			if (arrayProducts[pos].overdraft) {
				return true;
			}
		}
		return false;
	},
	configureBrand(brand) {
		this.setTitle(brand?.name);
		this.setFavicon(brand?.config?.favicon);
		this.setPrimaryColor(brand?.primaryColor);
		this.setSecondaryColor(brand?.secondaryColor);
	},
	setPrimaryColor(color) {
		document.querySelector('body').style.setProperty('--primaryColor', color);
	},
	setSecondaryColor(color) {
		document.querySelector('body').style.setProperty('--secondaryColor', color);
	},
	setTitle(name) {
		document.title = name;
	},
	setFavicon(favIcon) {
		let favicon = document.querySelector("link[rel~='icon']");
		if (!favicon) {
			favicon = document.createElement('link');
			favicon.rel = 'icon';
			document.getElementsByTagName('head')[0].appendChild(favicon);
		}
		favicon.href = favIcon;
	},
	getStyles(brand, component) {
		let style = '';
		if (brand?.config?.styles?.[component]) {
			brand.config.styles[component].forEach(e => {
				style = `${style} ${e[0]}${e[1]}`;
			});
		}
		return style;
	},
	haveBrandActionActive(brand, action) {
		return !brand?.config?.disabled?.includes(action);
	},
	disabledData(brand, name) {
		return !brand?.config?.disabledOnResume?.includes(name);
	}
};
